import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IShopCart } from 'src/app/Interfaces/Models/ShopCart.interface';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ServiceService } from 'src/app/services/service/service.service';
import { IBooking } from 'src/app/models/Booking.model';
import { IPax } from 'src/app/models/Pax.model';
import { ISchedule } from 'src/app/models/Schedule.model';
import { IService } from 'src/app/models/Service.model';
import { ProductTypeEnum } from 'src/app/models/enums/ProductType.enum';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterService } from 'src/app/services/router/router.service';
import { ShopcartService } from 'src/app/services/shopcart/shopcart.service';
import { StepEnum } from 'src/app/models/enums/Step.enum';
import { IProduct } from 'src/app/models/Product.model';
import { AddonService } from 'src/app/services/addon/addon.service';
import { AppService } from 'src/app/services/app/app.service';
import { ReservationService } from 'src/app/services/reservation/reservation.service';
import { DiscountService } from 'src/app/services/discount/discount.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingComponent implements OnInit, AfterViewInit {
  public lang: string = 'en-US';

  /* observers */
  public serviceList$: Observable<IService[]> = new Observable();
  public shopCart$: Observable<IShopCart> = new Observable();
  public booking$: Observable<IBooking> = new Observable();
  //public booking$: Observable<IBooking> = this.bookingService.booking$;
  //public booking: IBooking | null = null;
  public inventories$: Observable<Date[]> = new Observable();

  /* local selected */
  public inventoryList: Date[] = [];
  public productId: number = 0;
  public checkInDateSelected: Date = new Date();
  public currentVariant: ProductTypeEnum = ProductTypeEnum.SINGLE;
  public paxHasSelected: boolean = false;
  public scheduleHasSelected: boolean = false;

  /* disabled */
  public productSelectDisabled: boolean = false;
  public calendarDisabled: boolean = true;
  public nextDisabled: boolean = false;
  public showLoader: boolean = false;

  /* hidden */
  public calendarHidden: boolean = false;

  private currentPaxes: number = 0;

  constructor(
    //private cdr: ChangeDetectorRef,
    private appService: AppService,
    private serviceService: ServiceService,
    private bookingService: BookingService,
    private inventoryService: InventoryService,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private shopcartService: ShopcartService,
    private addonService: AddonService,
    private discountService: DiscountService,
    private reservationService: ReservationService
  ) {
    this.lang = this.appService.getCurrentLang().toLowerCase().includes('es')
      ? 'es-MX'
      : 'en-US';
  }

  async ngOnInit() {
    //console.log('BookingComponent.ngOnInit');

    // this.productId = 0;
    // this.currentPaxes = 0;
    // this.nextDisabled = false;

    try {
      this.serviceList$ = this.serviceService.services$;
      this.booking$ = this.bookingService.booking$;
      // this.bookingService.getBookingNew().subscribe((value) => {
      //   this.booking = value;
      //   // this.cdr.detectChanges();
      // });
      this.inventories$ = this.inventoryService.inventories$;
    } catch (error) {
      console.log(error);
    }
  }

  async ngAfterViewInit() {
    // console.log('BookingComponent.ngAfterViewInit');

    try {
      this.routerService.setStep(StepEnum.BOOKING);
      // // setTimeout(() => { this.booking$ = this.bookingService.booking$; }, 2000)
      
      // this.bookingService.booking$.subscribe((value) => {
      //   this.booking$ = value;
      //   this.cdr.detectChanges();
      // });

      await this.serviceService.loadServiceList();

      if (this.bookingService.isFullBooking()) {
        this.routerService.setBlock(false);
      }

      this.activatedRoute.queryParams.subscribe((params) => {
        if (params['product_id']) {
          this.productSelectDisabled = true;
          let productId = parseInt(params['product_id']);
          this.serviceService.setServiceSelected(productId);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async onServiceSelected(service: IService) {
    this.showLoader = true;
    this.addonService.cleanState();
    this.discountService.cleanState();
    this.bookingService.cleanState();
    this.shopcartService.cleanState();
    this.currentVariant = service.type;
    this.bookingService.addService(
      service.id,
      service.type,
      service.name,
      service.image,
      service.locationId,
      service.locationName
    );

    if (service.type === ProductTypeEnum.SINGLE) {
      await this.inventoryService.loadInventory(service.id, service.locationId);
    }

    if (service.type === ProductTypeEnum.COMBO) {
      await this.inventoryService.getInventoryCombo(service.id);
    }
    this.calendarHidden = false;
    this.calendarDisabled = false;
    this.showLoader = false;
  }

  async onSelectDate(checkInDate: Date) {
    this.showLoader = true;
    this.calendarHidden = true;
    this.addonService.cleanState();
    this.discountService.cleanState();
    this.shopcartService.cleanState();
    this.bookingService.addCheckInDate(checkInDate);
    await this.bookingService.loadProduct();
    this.showLoader = false;
  }

  async onChangeCalendar(
    $event: { startDate: Date; endDate: Date },
    productId: number
  ) {
    if (this.currentVariant === ProductTypeEnum.SINGLE) {
      let inventoryList = await this.inventoryService.loadInventoryByRange(
        productId,
        $event.startDate,
        $event.endDate
      );
      this.inventoryList = this.inventoryList.concat([...inventoryList]);
    }

    if (this.currentVariant === ProductTypeEnum.COMBO) {
      let inventoryList = await this.inventoryService.getInventoryComboByRange(
        productId,
        $event.startDate,
        $event.endDate
      );
      this.inventoryList = this.inventoryList.concat([...inventoryList]);
    }
  }

  public onChangePaxes(pax: IPax, productId: number) {
    const currentPaxes = pax.quantity;

    if (pax.id == 0 && currentPaxes <= 0) {
      setTimeout(() => {
        this.nextDisabled = true;
      });

      this.routerService.setBlock(true);
      this.bookingService.addPax(pax.id, currentPaxes);
      this.paxHasSelected = false;

      return;
    }

    if (this.scheduleHasSelected) {
      this.bookingService.setBookingFull(true);
      this.routerService.setBlock(false);

      setTimeout(() => {
        this.nextDisabled = false;
      });
    }
    
    this.bookingService.addPax(pax.id, currentPaxes);
    this.paxHasSelected = true;

    return;
  }

  public onScheduleSelected(schedule: ISchedule, productId: number) {
    let resultNextDisabled = true;

    this.bookingService.addSchedule(schedule, productId);
    this.routerService.setBlock(true);
    this.scheduleHasSelected = true;

    if (this.paxHasSelected) {
      this.routerService.setBlock(false);
      resultNextDisabled = false;
    }

    setTimeout(() => {
      this.nextDisabled = resultNextDisabled;
    });

    return;
  }

  public showCalendar() {
    this.bookingService.setBookingCalendar(false);
  }

  public goToClientForm() {
    this.bookingService.setBookingFull(true);
    this.routerService.goToClientForm();
  }

  public optionsForSelectSchedule(product: IProduct) {
    let result = product.scheduleList;

    // result = result.filter(inventory => inventory.);

    return result;
  }
}
