
import { Component, computed, signal } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from 'src/environment/environment';

import { Subject, Subscription } from 'rxjs';
import { IReservation } from 'src/app/Interfaces/Models/Reservation.interface';
import { BankTypeEnum } from 'src/app/models/enums/Bank.enum';
import { StepEnum } from 'src/app/models/enums/Step.enum';
import { BanksService } from 'src/app/services/banks/banks.service';
import { HttpService } from 'src/app/services/http/http.service';
import { PaypalService } from 'src/app/services/paypal/paypal.service';
import { ReservationService } from 'src/app/services/reservation/reservation.service';
import { RouterService } from 'src/app/services/router/router.service';
import { AppService } from 'src/app/services/app/app.service';
import { CurrencyEnum } from 'src/app/config/constants';

interface ITotalMinPaymentBuCurrency {
  [key: string]: number;
}

interface ITotalMinPaymentByWindow {
  [key: string]: ITotalMinPaymentBuCurrency;
}

interface ITotalMinPaymentByLocation {
  [key: number]: ITotalMinPaymentByWindow;
}

@Component({
  selector: 'app-payment-link.view',
  templateUrl: './payment-link.view.component.html',
  styleUrls: ['./payment-link.view.component.css']
})
export class PaymentLinkViewComponent {
  public paymentMethodOpened = signal<string | null>(null);

  public paymentMethods = BankTypeEnum;
  public mitpayment : string = "";

  public showLoader : boolean = true;
  public showPaymentError : boolean = false;
  public showPayments : boolean = true;

  // queryParams
  public amount : number = 0;
  public reservationId : string = "" ;

  // observers-
  public banks$ = this.bankService.bank$;
  public reservation$ = this.reservationService.reservation$;

  // subscriptions
  public firebaseSubscription : Subscription = new Subscription();

  //signals
  public paypalClient = computed(() => this.paypalService.paypal().clientId);
  public paypalLoading = this.paypalService.paypalLoading;

  constructor(
    private appService: AppService,
    private bankService : BanksService,
    private activedRoute : ActivatedRoute,
    private reservationService : ReservationService,
    private firebaseRealTimeService : AngularFireDatabase,
    private httpService : HttpService,
    private router : Router,
    private routerService : RouterService,
    private paypalService: PaypalService
  ) {}


  async ngOnInit() : Promise<void>
  {
    // appService
    console.log('PaymentLinkViewComponent');

    this.routerService.setStep(StepEnum.PAYMENT);

    this.reservationId = this.reservationService.getReservationId();

    // logic for payment url
    let reservationParam =
      this.activedRoute.snapshot.paramMap.get('reservationId');
    let amountParam = this.activedRoute.snapshot.queryParamMap.get('monto');

    if (reservationParam) {
      this.showPayments = false;
      this.reservationId = reservationParam;
      await this.reservationService.setUpReservation(reservationParam);
    }

    if (amountParam) {
      this.amount = parseFloat(amountParam);

      let reservation = await this.reservationService.getReservation(
        this.reservationId
      );

      const totalMinPaymentByLocation: ITotalMinPaymentByLocation =
        environment.TOTAL_MIN_PAYMENT;
      let totalMinPayment: number =
        totalMinPaymentByLocation[reservation.locationId]['LINK'][
          reservation.currencyCode
        ];

      if (!totalMinPayment) {
        if (reservation.currencyCode == CurrencyEnum.DOLLAR_US)
          totalMinPayment = 10;
        else if (reservation.currencyCode == CurrencyEnum.PESO_MX)
          totalMinPayment = 199;
      }

      console.log(this.amount, totalMinPayment);

      if (this.amount < totalMinPayment) {
        this.showLoader = true;
        this.router.navigate(['/support']);
      }
    }

    // check payment methods available
    let bankList = await this.bankService.getBanks();

    for await (let bank of bankList) {
      if (bank.type === this.paymentMethods.MIT) {
        this.mitpayment = await this.httpService.createMITOrder(
          this.reservationId,
          bank.id,
          this.amount
        );
        this.firebaseSubscription = this.firebaseRealTimeService
          .object(`reservations/${this.reservationId}`)
          .valueChanges()
          .subscribe(async (data: any) => {
            // if the payments attempts is more than 3 or equal, always send to support page
            if (data['paymentAttempts'] >= 3) {
              this.reservationService.setReservationId(data['reservationId']);
              this.router.navigate(['/support-reservation']);
            }

            // if the payment status are true send to confirm page
            if (data['paymentStatus']) {
              this.showPaymentError = false;
              this.router.navigate([
                '/reservation-confirmation',
                data.reservationId,
              ]);
            }

            if (data['paymentError']) {
              this.mitpayment = await this.httpService.createMITOrder(
                this.reservationId,
                bank.id
              );
              this.showPaymentError = true;
            }
          });
      }
      if (bank.type == this.paymentMethods.PAYPAL) {
        console.log('me ejecuto');
        await this.paypalService.getPaypal(bank.id);
      }
    }

    this.showLoader = false;
    document.querySelector('.global-loading')?.remove();
  }

  ngOnDestroy(){
    this.firebaseSubscription.unsubscribe();
  }

  onValidTermsAndConditions(event : boolean){
    this.showPayments = event;
  }
}
