<form class="client-form" action="" autocomplete="off" [formGroup]="billingForm">
  <div class="client-form--name">
    <mat-form-field appearance="outline">
      <mat-label>{{ "CHECKOUT.CLIENT_FORM.NAME" | translate }}</mat-label>
      <input matInput type="text" formControlName="name" />
      <mat-error *ngIf="validateRequired('name')">{{
        getErrorMessageRequired("name")
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ "CHECKOUT.CLIENT_FORM.LAST_NAME" | translate }}</mat-label>
      <input matInput type="text" formControlName="lastName" />
      <mat-error *ngIf="validateRequired('lastName')">{{
        getErrorMessageRequired("lastName")
      }}</mat-error>
    </mat-form-field>
  </div>

  <div class="client-form--email">
    <mat-form-field appearance="outline">
      <mat-label>{{ "CHECKOUT.CLIENT_FORM.EMAIL" | translate }}</mat-label>
      <input matInput formControlName="email" />
      <mat-error *ngIf="getEmail()">{{ getErrorMessageEmail() }}</mat-error>
    </mat-form-field>
    <!--
        <mat-form-field appearance="outline">
            <mat-label>{{'CHECKOUT.CLIENT_FORM.EMAIL_CONFIRMATION' | translate}}</mat-label>
            <input matInput formControlName="confirmEmail" (paste)="(false)">
            <mat-error *ngIf="validateRequired('confirmEmail')">{{getErrorMessageRequired('confirmEmail')}}</mat-error>
            <mat-error *ngIf="validateErrors('confirmEmail','emailMismatch')">Email confirmation does not match</mat-error>
        </mat-form-field>
        -->
  </div>

  <div class="client-form--phone">
    <mat-form-field appearance="outline">
      <mat-label>{{ "CHECKOUT.CLIENT_FORM.PHONE" | translate }}</mat-label>
      <input matInput type="tel" formControlName="phone" maxlength="15"(input)="onPhoneInput($event)" placeholder="4444444444" />
      <mat-error *ngIf="validateRequired('phone')">{{getErrorMessageRequired("phone")}}</mat-error>
      <!--mat-error *ngIf="billingForm.get('phone')?.hasError('pattern') && billingForm.get('phone')?.touched">
        El valor no es válido (debe tener entre 7 y 15 dígitos)
      </mat-error-->
    </mat-form-field>
  </div>

  <div class="client-form--localization">
    <mat-form-field appearance="outline">
      <mat-label>{{'CHECKOUT.CLIENT_FORM.COUNTRY' | translate}}</mat-label>
      <mat-select formControlName="country" [value]="client?.country">
        <mat-option *ngFor="let country of countryOptions" [value]="country.value.name">
          {{country.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="client-form--comments">
    <mat-form-field appearance="outline">
      <mat-label>{{ "CHECKOUT.CLIENT_FORM.COMMENTS" | translate }}</mat-label>
      <textarea
        matInput
        rows="2"
        cols="5"
        maxlength="250"
        formControlName="comments"
        (input)="onCommentsInput($event)"
      ></textarea>
    </mat-form-field>
  </div>
</form>

<!-- <code [innerHTML]="client | json"></code> -->
