import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IBooking } from 'src/app/models/Booking.model';
import { ShopcartService } from '../shopcart/shopcart.service';
import { ISchedule } from 'src/app/models/Schedule.model';
import { IProduct } from 'src/app/models/Product.model';
import { HttpService } from '../http/http.service';
import { DateService } from '../dates/date.service';
import { ProductTypeEnum } from 'src/app/models/enums/ProductType.enum';
import { InventoryService } from '../inventory/inventory.service';
import { IDiscount } from 'src/app/models/Discount.model';
import { ServiceService } from '../service/service.service';
import { ISelectScheduleItemDTO } from 'src/app/Interfaces/Responses/ISelectScheduleItemDTO';
import { PRODUCT_IS_NOT_IN_THE_COUPON_ERROR } from 'src/app/models/errors/coupon.error';
import { GetDiscountByIdResponse } from 'src/app/Interfaces/Responses/GetDiscountByIdResponse.dto';
import { GetDiscountById } from 'src/app/Interfaces/Responses/GetDiscountById.dto';
import { PaxTypeEnum } from 'src/app/models/Pax.model';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  private booking: IBooking = {
    bookingService: false,
    bookingCalendar: false,
    bookingPax: false,
    bookingSchedule: false,
    bookingFull: false,
    principalProductName: "",
    principalProductType: ProductTypeEnum.SINGLE,
    principalLocationId: 0,
    principalLocationName: "",
    principalProductImage: "",
    checkInDate: new Date(),
    principalProductId: 0,
    productList: [],

    promotionWeek: null,
    daysInAdvance: null,
    rulePax: 0,
  };

  private bookingSubject: BehaviorSubject<IBooking> = new BehaviorSubject(this.booking);
  public booking$: Observable<IBooking> = this.bookingSubject.asObservable();

  constructor(
    private serviceService: ServiceService,
    private shopCartService: ShopcartService,
    private inventoryService: InventoryService,
    private httpService: HttpService,
    private dateService: DateService
  ) { }

  public getBooking() {
    return this.bookingSubject.value;
  }

  public isFullBooking() : boolean{
    let { bookingFull } = this.bookingSubject.value;
    return bookingFull;
  }

  public setBookingFull(flag: boolean) {
    this.booking.bookingFull = flag;
    this.bookingSubject.next(this.booking);
  }

  public setBookingCalendar(flag: boolean) {
    this.booking.bookingCalendar = flag;
    this.bookingSubject.next(this.booking);
  }

  // Initialize the principal product, this functions are sooooo bad but in this company doesnt exist QA testing :(
  public addService(
    serviceId: number,
    serviceType: ProductTypeEnum,
    serviceName: string,
    serviceImage: string,
    locationId: number,
    locationName: string
  ) {
    this.booking.principalProductId = serviceId;
    this.booking.principalProductType = serviceType;
    this.booking.principalProductName = serviceName;
    this.booking.principalProductImage = serviceImage;
    this.booking.principalLocationId = locationId;
    this.booking.principalLocationName = locationName;
    this.booking.bookingService = true;
    this.serviceService.setServiceSelected(serviceId);
    this.bookingSubject.next(this.booking);
  }

  public addPax(paxId: number, quantity: number) {
    if (quantity > 0) {
      this.booking.bookingPax = true;
    }

    if (quantity <= 0) {
      this.booking.bookingPax = false;
    }

    // set always all products cause if single are only one and if combo always set the paxes of the main product to the rest
    this.booking.productList.forEach((product) => {
      let paxIndex = product.paxList.findIndex((pax) => pax.id === paxId);
      let pax = product.paxList[paxIndex];
      pax.quantity = quantity;
      product.availablePaxes = product.paxList.reduce(
        (counter, innerPax) => counter + innerPax.quantity,
        0
      );
      this.shopCartService.addPax(product.id, pax);
    });

    this.bookingSubject.next(this.booking);
  }

  public addCheckInDate(checkInDate: Date) {
    this.booking.bookingCalendar = true;
    this.booking.checkInDate = checkInDate;
    this.shopCartService.addCheckInDate(checkInDate);
    this.bookingSubject.next(this.booking);
  }

  public addSchedule(schedule: ISchedule, productId: number) {
    this.booking.bookingSchedule = true;
    this.shopCartService.addCheckInDate(schedule.date);
    this.shopCartService.addSchedule(productId, schedule);
    this.bookingSubject.next(this.booking);
  }

  // evaluate if the current product are combo or single and update the local state
  public async loadProduct(discountCode: string | null = null) {
    if (this.booking.principalProductType === ProductTypeEnum.SINGLE) {
      await this.loadRegularProduct(discountCode);
    }

    if (this.booking.principalProductType === ProductTypeEnum.COMBO) {
      await this.loadCombo();
    }
  }

  // iterate the product list to get pax prices and schedules
  public async loadRegularProduct(discountCode: string | null = null) {
    let booking = { ...this.booking };
    let product: IProduct = {
      id: booking.principalProductId,
      name: booking.principalProductName,
      locationId: booking.principalLocationId,
      locationName: booking.principalLocationName,
      availablePaxes: 0,
      paxList: [],
      scheduleList: [],
    };

    this.shopCartService.cleanState();
    this.shopCartService.addProduct(
      booking.principalProductId,
      booking.principalProductName,
      booking.principalLocationId,
      booking.principalLocationName,
      booking.principalProductType,
      booking.principalProductImage
    );

    let paxResponse = await this.httpService.getPaxList({
      productId: booking.principalProductId,
      discountCode: discountCode,
    });
    let scheduleResponse = await this.httpService.getScheduleList({
      locationId: booking.principalLocationId,
      productId: booking.principalProductId,
      checkInDate: this.dateService.toString(booking.checkInDate),
    });

    //console.log('loadRegularProduct', paxResponse, booking);

    product.paxList = paxResponse.data.map((pax) => {
      let paxDefault =
        booking.productList.map((productPreset) =>
          productPreset.paxList.find((paxPreset) => paxPreset.id == pax.paxId)
        );

      //console.log('paxDefault', paxDefault);

      return {
        id: pax.paxId,
        name: pax.paxName,
        price: pax.paxPrice,
        priority: pax.paxPriority,
        total: 0,
        subtotal: 0,
        discount: 0,
        quantity: paxDefault[0]?.quantity ?? 0,
        blocked: false,
      };
    });

    product.scheduleList = scheduleResponse.data.map((schedule) => {
      return this.scheduleTransformer(schedule);
    });

    this.booking.productList = [];
    this.booking.productList.push(product);

    this.booking.promotionWeek = null;
    this.bookingSubject.next(this.booking);
  }

  async loadCombo() {
    try {
      this.shopCartService.cleanState();

      let booking = { ...this.booking };
      let productList = [];

      this.shopCartService.setCombo(this.booking.principalProductId);
      //the productId is the discountId to access the combo in discount endpoint
      const data: GetDiscountById = await this.httpService.getDiscountById(booking.principalProductId);

      for await (const product of data.productList) {
        this.shopCartService.addProduct(
          product.productId,
          product.productName,
          product.locationId,
          product.locationName,
          booking.principalProductType,
          product.productImage
        );

        const schedule = await this.httpService.getScheduleList({
          locationId: product.locationId,
          productId: product.productId,
          checkInDate: this.dateService.toString(booking.checkInDate),
        });

        let productFormatted: IProduct = {
          id: product.productId,
          name: product.productName,
          paxList: [],
          locationId: product.locationId,
          locationName: product.locationName,
          availablePaxes: 0,
          // format the shcedules
          scheduleList: schedule.data.map((scheduleItem) => {
            return this.scheduleTransformer(scheduleItem);
          }),
        };

        // check if has a -1 that means have all paxes and set paxes of the parent
        let paxGeneral = product.paxList.find((pax) => pax.paxId == -1);
        let parentProduct = data.productList[0];

        let sumPaxPriceValue = {
          [Number(PaxTypeEnum.SINGLE)]: data.productList.map((i) => i.paxList.find((j) => j.paxId == PaxTypeEnum.SINGLE)?.paxPriceValue).reduce((p,c) => (p ?? 0)+(c ?? 0), 0),
          [Number(PaxTypeEnum.CHILD)]: data.productList.map((i) => i.paxList.find((j) => j.paxId == PaxTypeEnum.CHILD)?.paxPriceValue).reduce((p,c) => (p ?? 0)+(c ?? 0), 0),
          [Number(PaxTypeEnum.INFANT)]: data.productList.map((i) => i.paxList.find((j) => j.paxId == PaxTypeEnum.INFANT)?.paxPriceValue).reduce((p,c) => (p ?? 0)+(c ?? 0), 0),
        };

        if (paxGeneral != undefined) {
          productFormatted.paxList = parentProduct.paxList.map((pax, idx, arr) => {
            let totalToDiscount: number = 0;

            // when it is a COMBO you should take the rates from the paxPriceValue (priceUnit) instead of just paxPrice (pricePublic)
            // accounting dispersion
            if (this.booking.principalProductType == ProductTypeEnum.COMBO) {
              if (pax.paxPrice) {
                totalToDiscount =
                  pax.paxPrice - (sumPaxPriceValue[pax.paxId] ?? 0);
              }
            }

            return {
              id: pax.paxId,
              name: pax.paxName,
              price: paxGeneral!.paxPrice,
              generalId: paxGeneral?.paxId,
              total: 0,
              subtotal: 0,
              discount: totalToDiscount,
              quantity: 0,
              priority: 0,
              blocked: false,
            };
          });

          productList.push(productFormatted);
          continue;
        }

        //otherwise only formatt the initial values to interact
        // console.log('otherwise', product.paxList);
        let paxFormatted = product.paxList.map((pax) => {
          let totalToDiscount: number = 0;

          // when it is a COMBO you should take the rates from the paxPriceValue (priceUnit) instead of just paxPrice (pricePublic)
          // accounting dispersion
          if (this.booking.principalProductType == ProductTypeEnum.COMBO) {
            if (pax.paxPrice) {
              totalToDiscount =
                pax.paxPrice - (sumPaxPriceValue[pax.paxId] ?? 0);
            }
          }

          return {
            id: pax.paxId,
            name: pax.paxName,
            price: pax.paxPrice,
            total: 0,
            subtotal: 0,
            discount: totalToDiscount,
            quantity: 0,
            priority: 0,
            blocked: false,
          };
        });

        productFormatted.paxList = paxFormatted;

        productList.push(productFormatted);
        continue;
      }
      this.booking.productList = productList;
      this.bookingSubject.next(this.booking);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  loadDiscount(discount: IDiscount) {
    this.booking.promotionWeek = discount.week;
    this.booking.daysInAdvance = discount.daysInAdvance;
    this.booking.rulePax = discount.rule;

    for (let product of this.booking.productList) {
      let discountIndex = discount.productList.findIndex( (discountItem) => discountItem.id == product.id );
      // if the product dont apply send error
      if (
        discountIndex == -1
        // // &&
        // // this.booking.principalProductType != ProductTypeEnum.COMBO
      ) {
        throw false;
      }

      // // if (
      // //   this.booking.principalProductType == ProductTypeEnum.COMBO
      // //   &&
      // //   this.booking.principalProductName.toUpperCase() != product.name.toUpperCase()
      // // ) {
      // //   continue;
      // // }

      for (let pax of product.paxList) {
        let discountPaxIndex = discount.productList[discountIndex].paxList.findIndex((discountPaxItem) => discountPaxItem.id == pax.id);
        // if the pax dont apply only past to another
        if (discountPaxIndex == -1) {
          continue;
        }

        let discountPerPax = discount.productList[discountIndex].paxList[discountPaxIndex].price;

        // apply discount percent
        if (
          discount.productList[discountIndex].paxList[discountPaxIndex].currencyCode == null
        ) {
          discountPerPax = (discountPerPax / 100) * pax.price;
        }

        pax.discount = parseFloat( discountPerPax.toFixed(2) );
        pax.discountTotal = parseFloat( (discountPerPax * pax.quantity).toFixed(2) );
        pax.subtotal = parseFloat( (pax.quantity * pax.price).toFixed(2) );
        pax.total = parseFloat( (pax.subtotal - pax.discountTotal).toFixed(2) );
      }
    }

    this.bookingSubject.next(this.booking);
  }

  unloadDiscount() {
    this.booking.promotionWeek = null;
    this.booking.daysInAdvance = null;
    this.booking.rulePax = 0;

    Array.from(this.booking.productList).forEach((product) => {
      Array.from(product.paxList).forEach((pax) => {
        pax.discount = 0;
        pax.discountTotal = 0;
        pax.subtotal = parseFloat((pax.quantity * pax.price).toFixed(2));
        pax.total = parseFloat((pax.subtotal - pax.discountTotal).toFixed(2));
      });
    });

    this.bookingSubject.next(this.booking);
  }

  public cleanState() {
    this.booking = {
      bookingService: false,
      bookingCalendar: false,
      bookingPax: false,
      bookingSchedule: false,
      bookingFull: false,
      principalProductName: '',
      principalProductType: ProductTypeEnum.SINGLE,
      principalLocationId: 0,
      principalLocationName: '',
      principalProductImage: '',
      checkInDate: new Date(),
      principalProductId: 0,
      productList: [],

      promotionWeek: null,
      daysInAdvance: null,
      rulePax: 0,
    };

    this.bookingSubject.next(this.booking);
  }

  private scheduleTransformer(schedule: ISelectScheduleItemDTO): ISchedule {
    let startTime = this.dateService.stringToTime(
      schedule.scheduleDateTimeStart
    );
    let endTime = this.dateService.stringToTime(schedule.scheduleDateTimeEnd);
    let date = this.dateService.stringToDate(schedule.scheduleDate);
    let startDate = this.dateService.stringToDate(
      this.dateService.addTime(date, startTime)
    );
    let endDate = this.dateService.stringToDate(
      this.dateService.addTime(date, endTime)
    );
    return {
      id: schedule.scheduleId,
      inventoryId: schedule.invetoryId,
      date: startDate,
      dateEnd: endDate,
      selected: false,
    };
  }
}
