import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { HttpService } from '../http/http.service';
import { DateService } from '../dates/date.service';

import { IDiscount } from 'src/app/models/Discount.model';

@Injectable({
  providedIn: 'root',
})
export class DiscountService {
  private discount: IDiscount | null = null;
  private discountSubject: BehaviorSubject<IDiscount | null> =
    new BehaviorSubject(this.discount);
  public discount$: Observable<IDiscount | null> =
    this.discountSubject.asObservable();

  constructor(
    private httpService: HttpService,
    private dateService: DateService
  ) {}

  public getCurrentDiscount() {
    return this.discountSubject.value;
  }

  public setDiscount(discount: IDiscount | null) {
    return this.discountSubject.next(discount);
  }

  // only for discounts by promocode
  public async getDiscount(discountCode: string, checkInDate: Date) {
    try {
      const { data, length } = await this.httpService.validateDiscount({
        checkInDate: this.dateService.toString(checkInDate),
        discountCode: discountCode,
      });

      if (!data) {
        throw false;
      }

      let discount: IDiscount = {
        id: data.discountId,
        name: data.discountName,
        description: data.discountDescription,
        image: data.discountImage,
        code: data.discountCode,
        BOGOA: data.discountBOGOA,
        BOGOB: data.discountBOGOB,
        week: data.discountWeek,
        daysInAdvance: data.daysInAdvance,
        productList: data.productList.map((product) => {
          return {
            id: product.productId,
            name: product.productName,
            paxList: product.paxList.map((pax) => {
              return {
                id: pax.paxId,
                name: pax.paxName,
                price: pax.paxPrice,
                currencyCode: pax.currencyCode,
                currencyId: pax.currencyId,
              };
            }),
          };
        }),
        rule: data.discountRuleForPax,
      };

      return discount;
    } catch (error) {
      throw error;
    }
  }

  // only for combos
  public async getDiscountById(discountId: number) {
    try {
      const data = await this.httpService.getDiscountById(discountId);
      return data.productList;
    } catch (error) {
      throw error;
    }
  }

  public cleanState() {
    this.discount = null;
    this.discountSubject.next(this.discount);
  }
}
